import { Link } from "gatsby"
import React from "react"
import { useTranslation } from "react-i18next"
import './footer.component.less';

import Logo_ca from "../../assets/img/logo/logo_ca.svg"
import Appstore from "../../assets/img/logo/appstore-en.svg"
import GooglePlay from "../../assets/img/logo/google-play-en.svg"
import Skolkovo from "../../assets/img/logo/skolkovo.png"
import Fasie from "../../assets/img/logo/fasie.svg"

const FooterComponent = () => {
    const { i18n, t } = useTranslation()

    let currentDate = new Date().getFullYear();

  return (
      <div>
          <hr className="footer_hr" />
          <div className="container">
              <div className="row footer_container__row-1">
                  <div className="col-11 col-sm-11 col-md-8 col-lg-5 col-xl-5 col-xga-4 col-fhd-4 col-rt-4 footer_container__col-1">
                      <img alt="" src={Logo_ca} className="footer_logo" />
                          <div className="footer__title">
                              <a href={'tel:' + t("phoneNumber").replace(/\s/g, '')}>{t("phoneNumber")}</a>
                              <br />
                              <a className="footer__a_mail" href="mailto:info@cityair.ru">{t("mailInfoCityair")}</a>
                              <br />
                              <a className="footer__a_mail" href="mailto:sales@cityair.ru">{t("mailInfoSales")}</a>
                          </div>
                          <div className="footer__text footer__text-2">
                              {t("novosibirskAcademicTown")}
                          </div>
                  </div>
                  <div className="col-xga-1 col-fhd-1 col-rt-1 footer_1600_display_none"/>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 col-xga-2 col-fhd-2 col-rt-2 footer_container__col-2">
                      <div className="footer__name_page">
                          <Link to={"/" + i18n.language + "/monitoring-networks"} className="footer__name_page-a">Сети контроля загрязнения воздуха</Link>
                      </div>
                      <div className="footer__name_page">
                          <Link to={"/" + i18n.language + "/equipment"} className="footer__name_page-a">{t("equipment")}</Link>
                      </div>
                      <div className="footer__name_page">
                          <Link to={"/" + i18n.language + "/modeling"} className="footer__name_page-a">{t("simulation")}</Link>
                      </div>
                      <div className="footer__name_page">
                          <Link to={"/" + i18n.language + "/software"} className="footer__name_page-a">{t("software2")}</Link>
                      </div>
                      <div className="footer__name_page">
                          <Link to={"/" + i18n.language + "/documentation"} className="footer__name_page-a">{t("documentation")}</Link>
                      </div>
                  </div>
                  <div
                      className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 col-xga-2 col-fhd-2 col-rt-2 footer_container__col-3">
                      <div>
                          <div className="footer__name_page">
                              <a className="footer__name_page-a" href="https://mycityair.ru/">{t("personalAccount")}</a>
                          </div>
                          <div className="footer__name_page">
                              <Link to={"/" + i18n.language + "/about"} className="footer__name_page-a">{t("aboutTheCompany")}</Link>
                          </div>
                          <div className="footer__name_page">
                              <Link to={"/" + i18n.language + "/contacts"} className="footer__name_page-a">{t("contacts")}</Link>
                          </div>
                      </div>
                  </div>
                  <div
                      className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xga-3 col-fhd-3 col-rt-3 order-1 order-sm-1 order-md-1 order-lg-2 order-xl-2 order-xga-2 footer_container__col-4">
                      <div className="row">
                          <div className="col-12 col-sm-12 col-md-6 col-lg-12 col-xl-12 col-xga-12 col-fhd-12 col-rt-12">
                              <div className="footer_container_2__subtitlle">
                                  {t("exploreTheAir")}
                              </div>
                          </div>
                          <div
                              className="col-12 col-sm-12 col-md-6 col-lg-12 col-xl-12 col-xga-12 col-fhd-12 col-rt-12">
                              <div className="footer_container__string">
                                  <a href="https://apps.apple.com/ru/app/cityair/id1473415398" className="footer__img_app-link">
                                      <img alt="" src={Appstore} className="footer__img_app" />
                                  </a>
                                  <a href="https://play.google.com/store/apps/details?id=com.cityair.mobile" className="footer__img_app-link">
                                      <img alt="" src={GooglePlay} className="footer__img_app" />
                                  </a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <hr className="footer_hr" />
          <div className="container">
              <div className="row footer_container__row-2">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xga-5 col-fhd-5 col-rt-6">
                      <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xga-11 col-fhd-11 col-rt-11">
                                <div className="footer__c">
                                    <div className="footer__copyright">ОГРН 1187746313336 от 20 марта 2018&nbsp;г., ИНН&nbsp;7731400381, КПП&nbsp;547301001</div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xga-11 col-fhd-11 col-rt-11">
                              <div className="footer__c">
                                <div className="footer__copyright">
                                    ©<span id="currentYear">{currentDate} </span>
                                    <span>{t("allRightsReserved")}</span>
                                </div>
                                <span className="footer__user_right">
                                    <a className="footer__link_user_right"  download href="/docs/terms_of_use.docx">Пользовательское соглашение</a>
                                </span>
                              </div>
                          </div>
                      </div>
                  </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4 col-xga-4 col-fhd-4 col-rt-4">
                    <div className="footer__skolkovo">
                        <a href="https://sk.ru/" target="_blank"><img alt="" src={Skolkovo} target="_blank" className="footer__img_skolkovo" /></a>
                        <div className="footer__skolkovo-text">{t("researchIs")}</div>
                    </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 offset-lg-5 offset-xl-0 col-xl-3 col-xga-3 col-fhd-3 col-rt-3">
                    <div className="footer__fasie">
                        <a href="https://fasie.ru/"><img alt="" src={Fasie} target="_blank" className="footer__img_fasie" /></a>
                        <div className="footer__fasie-text">При поддержке <br/>Фонда содействия инновациям</div>
                    </div>   
                </div>
              </div>
          </div>
      </div>
  )
}

FooterComponent.propTypes = {}

FooterComponent.defaultProps = {}

export default FooterComponent
