import { Link } from "gatsby"
import React, {useEffect} from "react"
import { useTranslation } from "react-i18next"
import './header.component.less';
import OutsideClickHandler from 'react-outside-click-handler';
import {useI18next} from "gatsby-plugin-react-i18next";

const HeaderComponent = () => {
    const { originalPath } = useI18next()
    const { i18n, t } = useTranslation('ru')
    let isOpenMobileMenu = false;
    let isOpenDropDownMenu = false;

    useEffect(() => {
        let idMenu = originalPath
        if (originalPath === '/' || originalPath === '/monitoring-networks/' || originalPath === '/equipment/' || originalPath === '/modeling/' || originalPath === '/software/' || originalPath === '/about-project/' || originalPath === '/documentation/' || originalPath === '/contacts/') {
                     idMenu.slice(1, -1) === '' ? document.getElementById('monitoring-networks').classList.add('menu__dropdown_str-active') : document.getElementById(idMenu.slice(1, -1)).classList.add('menu__dropdown_str-active')
        }
            if (originalPath === '/' || originalPath === '/monitoring-networks/' || originalPath === '/equipment/' || originalPath === '/modeling/' || originalPath === '/software/' || originalPath === '/documentation/') {
                if (!document.getElementById('menu__button').classList.contains('menu__button-active')) {
                    document.getElementById('menu__button').classList.add('menu__button-active')
                }
            } else {
                if (document.getElementById('menu__button').classList.contains('menu__button-active'))
                    document.getElementById('menu__button').classList.remove('menu__button-active')
            }
    });

    function openDropdownMenu () {
        isOpenDropDownMenu = !isOpenDropDownMenu;
        isOpenDropDownMenu ? document.getElementById('arrow_down').classList.add('arrow_down-active') : document.getElementById('arrow_down').classList.remove('arrow_down-active');
        isOpenDropDownMenu ? document.getElementById('menu__dropdown').classList.add('menu__dropdown-active') : document.getElementById('menu__dropdown').classList.remove('menu__dropdown-active');
    }

    function closeDropdownMenu () {
        isOpenDropDownMenu = false;
        if (document.getElementById('arrow_down').classList.contains('arrow_down-active')) document.getElementById('arrow_down').classList.remove('arrow_down-active');
        if (document.getElementById('menu__dropdown').classList.contains('menu__dropdown-active')) document.getElementById('menu__dropdown').classList.remove('menu__dropdown-active');
    }

    function openMobileMenu (menuBtn) {

        isOpenMobileMenu = !isOpenMobileMenu;
        isOpenMobileMenu ? document.getElementById('menu-btn').classList.add('open')
            : document.getElementById('menu-btn').classList.remove('open');

        isOpenMobileMenu ? document.getElementById('background_for_menu').classList.add('background_for_menu-active')
            : document.getElementById('background_for_menu').classList.remove('background_for_menu-active');

        isOpenMobileMenu ? document.getElementById('main_header__container').classList.add('main_header__container-active')
            : document.getElementById('main_header__container').classList.remove('main_header__container-active');
    }
    /* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
    return (
        <div>
            <div id="background_for_menu" className="background_for_menu" />
            <div className="mobile-header">
                <div className="mobile-header__line">
                    <Link
                        to="/"
                        language="ru"
                        className="logo logo-mobile"
                    />
                    <div className="mobile-header__right-side">
                        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                        <div id="menu-btn" className="menu-btn" onClick={()=>openMobileMenu()}>
                            <div className="menu-btn__burger" />
                        </div>
                    </div>
                </div>  
            </div>  
            

            <div className="main_header">
                <div id="main_header__container" className="container main_header__container">
                    <Link
                        to="/"
                        className="logo logo-desktop"
                    />
                    <div className="menu menu-left">
                        <OutsideClickHandler onOutsideClick={() => closeDropdownMenu()}>
                            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                            <div className="menu__item" onClick={()=>openDropdownMenu()}>
                                <div className="menu__button" id="menu__button">
                                    {t("product")}
                                    <div id="arrow_down" className="arrow_down">
                                        <svg width="19" height="11" viewBox="0 0 19 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.5 7.68046L2.31679 0.402728C1.78679 -0.134243 0.927495 -0.134243 0.397497 0.402728C-0.1325 0.939699 -0.1325 1.8103 0.397498 2.34727L8.54035 10.5973C9.07035 11.1342 9.92965 11.1342 10.4596 10.5973L18.6025 2.34727C19.1325 1.8103 19.1325 0.939698 18.6025 0.402727C18.0725 -0.134244 17.2132 -0.134244 16.6832 0.402727L9.5 7.68046Z" fill="#5D31BF"/>
                                        </svg>
                                    </div>
                                </div>
                                <div id="menu__dropdown" className="menu__dropdown" >
                                    <Link id="monitoring-networks" onClick={()=>openMobileMenu(true)} activeClassName="menu__dropdown_str-active" to={"/" + i18n.language + "/monitoring-networks"}><div className="menu__dropdown_str">Сети контроля</div></Link>
                                    <Link id="equipment" onClick={()=>openMobileMenu(true)} activeClassName="menu__dropdown_str-active" to={"/" + i18n.language + "/equipment"}><div className="menu__dropdown_str">{t("equipment")}</div></Link>
                                    <Link id="modeling" onClick={()=>openMobileMenu(true)} activeClassName="menu__dropdown_str-active" to={"/" + i18n.language + "/modeling"}><div className="menu__dropdown_str">{t("simulation")}</div></Link>
                                    <Link id="software" onClick={()=>openMobileMenu(true)} activeClassName="menu__dropdown_str-active" to={"/" + i18n.language + "/software"}><div className="menu__dropdown_str">{t("software")}</div></Link>
                                    <Link id="documentation" onClick={()=>openMobileMenu(true)} activeClassName="menu__dropdown_str-active" to={"/" + i18n.language + "/documentation"}><div className="menu__dropdown_str">{t("documentation")}</div></Link>
                                </div>
                            </div>
                        </OutsideClickHandler>
                        <Link id="about-project" onClick={()=>openMobileMenu(false)} activeClassName="menu__dropdown_str-active" to={"/" + i18n.language + "/about"}><div className="menu__item">{t("aboutTheCompany")}</div></Link>
                        <Link id="contacts" onClick={()=>openMobileMenu(false)} activeClassName="menu__dropdown_str-active" to={"/" + i18n.language + "/contacts"}><div className="menu__item">{t("contacts")}</div></Link>
                    </div>
                    <div className="menu menu-center">
                        <div className="menu__item menu__item-links">
                            <a className="telegram-link" href="https://t.me/cityair_news" target="_blank" rel="noopener noreferrer">
                                <svg className="telegram-link__icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><g clip-path="url(#clip0_2603_140)"><path class="telegram-link__icon-path"  d="M12.005 24.01C18.6352 24.01 24.01 18.6352 24.01 12.005C24.01 5.37482 18.6352 0 12.005 0C5.37482 0 0 5.37482 0 12.005C0 18.6352 5.37482 24.01 12.005 24.01Z" fill="black"/><path fill-rule="evenodd" clip-rule="evenodd" d="M5.4277 11.8751C8.92624 10.3557 11.2553 9.34615 12.4248 8.85636C15.7534 7.46694 16.4531 7.22704 16.9029 7.21704C17.0029 7.21704 17.2228 7.23703 17.3727 7.35698C17.4927 7.45694 17.5227 7.58689 17.5426 7.68685C17.5626 7.7868 17.5826 7.99672 17.5626 8.15665C17.3827 10.0559 16.603 14.6639 16.2032 16.7831C16.0333 17.6827 15.7034 17.9826 15.3835 18.0125C14.6838 18.0725 14.1541 17.5527 13.4843 17.1129C12.4248 16.4232 11.835 15.9934 10.8055 15.3137C9.61595 14.534 10.3856 14.1042 11.0653 13.4045C11.2453 13.2245 14.314 10.4257 14.374 10.1758C14.384 10.1458 14.384 10.0259 14.314 9.9659C14.244 9.90592 14.1441 9.92591 14.0641 9.9459C13.9541 9.9659 12.2748 11.0854 9.00621 13.2945C8.52641 13.6244 8.09659 13.7843 7.70675 13.7743C7.27693 13.7643 6.45727 13.5344 5.83753 13.3345C5.08784 13.0946 4.48809 12.9646 4.53807 12.5448C4.56806 12.3249 4.86793 12.105 5.4277 11.8751Z" fill="white"/></g><defs><clipPath id="clip0_2603_140"><rect width="24" height="24" fill="white"/></clipPath></defs></svg>
                                <span className="telegram-link__text">Новости компании</span>
                            </a>
                        </div>
                        <div className="menu__item menu__item-links"><a className="a_arrow_link" href="https://mycityair.ru" target="_blank" rel="noopener noreferrer">{t("personalAccount")}<div className="arrow_link" /></a></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

HeaderComponent.propTypes = {}

HeaderComponent.defaultProps = {}

export default HeaderComponent
