/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import HeaderComponent from "./header/header.component"
import FooterComponent from "./footer/footer.component";

import "./layout.less"

const Layout = ({ children }) => {

    return (
        <>
            <HeaderComponent />
            <div style={{
                margin: `0 auto`,
                paddingTop: 0,
                }}
            >
                <main>{children}</main>
            </div>
            <FooterComponent />
        </>
    )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
